import {
    axiosInstance,
    axiosWithoutLoading
} from "@/libs/axios";
import store from '@/store';
import router from '@/router'

function handleGetItem({
    fieldId,
    fieldName,
    amount,
    element
}) {
    return {
        id: fieldId,
        name: fieldName,
        amount: amount,
        product: element.getAttribute('data-id')
    };
}

async function getFormData() {
    let $inputs = document.querySelectorAll('.field-item-cart');
    let formData = {};
    if ($inputs.length) {
        for (let index = 0; index < $inputs.length; index++) {
            const element = $inputs[index];
            const fieldName = element.getAttribute('name');
            const fieldId = element.getAttribute('id');

            if (!(fieldName in formData)) {
                if (element.getAttribute('data-mode') == 'array') {
                    formData[fieldName] = [];
                } else {
                    formData[fieldName] = {};
                }
            }

            //campo que tem quantidade
            if (element.getAttribute('type') == 'text') {
                if (element.getAttribute('data-mode') == 'array') {
                    formData[fieldName].push(handleGetItem({
                        fieldId,
                        fieldName,
                        amount: element.value,
                        element
                    }));
                } else {
                    formData[fieldName] = (handleGetItem({
                        fieldId,
                        fieldName,
                        amount: element.value,
                        element
                    }));
                }
            } else {
                if (element.checked || element.getAttribute('type') == 'hidden') {
                    if (element.getAttribute('data-mode') == 'array') {
                        formData[fieldName].push(handleGetItem({
                            fieldId,
                            fieldName,
                            amount: 1,
                            element
                        }));
                    } else {
                        formData[fieldName] = (handleGetItem({
                            fieldId,
                            fieldName,
                            amount: 1,
                            element
                        }));
                    }
                }
            }
        }
    }

    return formData;
}

export default {
    setActiveComment(context, value) {
        context.dispatch('Site/toggleBackdrop', value, {
            root: true
        });
        context.commit('setActiveComment', value);
    },
    setIsActiveViewCart(context, value) {
        context.dispatch('Site/toggleBackdrop', value, {
            root: true
        });
        context.commit('setIsActiveViewCart', value)
    },
    setIsActiveSuggestions(context, value) {
        context.commit('setIsActiveSuggestions', value)
    },
    setIsActiveBtnViewCart(context, value) {
        context.commit('setIsActiveBtnViewCart', value)
    },
    setProductSuggestions(context, value) {
        context.commit('setProductSuggestions', value)
    },
    deletePositionSuggestions(context, value) {
        context.commit('deletePositionSuggestions', value)
    },
    resetSuggestions(context) {
        context.commit('resetSuggestions')
    },
    setCart(context, value) {
        context.commit('setCart', value)
    },
    loadCart(context) {
        return axiosWithoutLoading({
            method: 'GET',
            url: 'cart'
        }).then(response => response.data.data)
            .then(data => {
                context.commit("setCart", data);
                if (data && data.totalItems) {
                    context.commit("setIsActiveBtnViewCart", true);
                }
                return data;
            })
    },
    async addCartItem(context) {
        let formData = await getFormData();
        let get = context.getters;
        const isTakeAway = store.getters['TakeAway/isTakeAway'];
        let autoProds = store.getters['Site/storeConfig'].autoProds ?? null;

        let data = {
            store: get.productToAdd,
            suggestions: get.productSuggestions,
            formdata: formData,
            isTakeAway,
            autoProds
        };
        axiosInstance({
            method: 'POST',
            url: `cart/add-item/${get.productToAdd.cod_produto}/${get.productToAdd.familia}`,
            data
        }).then(response => response.data.data)
            .then(data => {
                router.push({name: 'menu'})
                // context.dispatch('Modal/setAlert', {
                //     message: "success.cartItemAdded",
                //     url: "menu",
                //     type: 'success',
                // }, { root: true })
            });
    },
    /**
     * metodo utilizado no primeiro momento para adicionar o novo produto
     * no objeto
     * @param {*} context 
     * @param {Object} product 
     */
    resetAddProdut(context, product) {
        let value = {
            cod_produto: product.cod_produto,
            familia: product.familia,
            precovenda: parseFloat(product.precovenda),
            suggestions: product.produtosSugeridos,
            //valor total do item final
            //inicia com o preço do item
            priceTotal: parseFloat(product.precovenda),
            amount: 1,
            compositions: {},
            isMenu: (('niveismenu' in product) && product.niveismenu.length ? true : false),
        };

        /**
         * se existir niveis e o nivel 1 for obrigatorio 
         * todos os produtos dele já sao selecionados 
         * automaticamente
         */
        if ('niveismenu' in product && product.niveismenu.length) {
            let firstPosition = product.niveismenu[0];
            if (firstPosition.obrigatorio) {
                firstPosition.products.map((productNivel) => {
                    if (!(`nivel-${firstPosition.nivel}` in value.compositions)) {
                        value.compositions[`nivel-${firstPosition.nivel}`] = [];
                    }
                    value.compositions[`nivel-${firstPosition.nivel}`].push(productNivel);

                    /**
                     * verifica os itens complementares se existem valores definidos como 
                     * padrao 1 se existir precisa atualizar também valores do carrinho
                     * como também itens selecionados
                     */
                    if (productNivel.complementares) {
                        productNivel.complementares.map((complement) => {
                            if (complement.fixed_qtd) {
                                let nameIndex = `nivelId:${firstPosition.nivel}-complementProdId:${productNivel.codigo}`;

                                if (!(nameIndex in value.compositions)) {
                                    value.compositions[nameIndex] = [];
                                }
                                //default amount annd indexname
                                complement.amount = complement.fixed_qtd;
                                complement.idIndex = `${nameIndex}-complementId:${productNivel.codigo}`;

                                value.compositions[nameIndex].push(complement);
                                //##atualiza o valor total do carrinho##
                                //era pra funcionar com isso mas diogo segue uma logica diferente do que o zs retorna
                                // value.priceTotal += (complement.fixed_qtd * complement.preco)
                                if (complement.obrigatorio) {
                                    //quantidade fixa em 1 quando é obrigatorio
                                    value.priceTotal += (1 * complement.preco)
                                }
                            }
                        });
                    }
                });
            }
        }

        context.commit('setProductTriggers', product);
        context.commit("resetProductToAdd", value);

        setTimeout(() => {
            context.commit('updatePriceTotalCart')
        }, 500);
    },
    setProductTrigger(context, {
        nameIndex,
        product
    }) {
        context.commit('setProductTrigger', {
            nameIndex,
            product
        })
    },
    setProductToAdd(context, value) {        
        context.commit("setProductToAdd", value);
        //estudar como que resolver o render dos campos
        setTimeout(() => {
            context.commit('updatePriceTotalCart')
        }, 300);
    },
    toogleButtonAddCart(context, value) {
        context.commit('toogleButtonAddCart', value)
    }
};