import store from "@/store";
import Geolocation from "./Geolocation";

/**
 * Serviço para gerenciar verificações que existe 
 * na loja
 */
export default class Store {
    constructor() {
        this.store = store.getters['Site/storeConfig'];
        this.serial = store.getters['Site/storeSerial'];
        this.geolocation = store.getters['Site/geolocation'];
        this.geolocationNeed = store.getters['Site/geolocationNeed'];

        if (!this.store) {
            throw new TypeError("Error: StoreConfig content not defined");
        }
    }

    /**
     * @method {método verifica se o botão de pagamento está ativo para exibir
     * ou nao ao user}
     * @return {boolean}
     */
    btnViewTable(){        
        let activePayment = this.store.biipPagamento || this.store.verConta;
                
        return this.hasTableNumber() && !this.hasPrePayment() && !this.isMenuOnly() && activePayment;
    }

    /**
     * @method { biip pay - modo do biip web onde apenas pagamentos são aceitos.}
     * @return {boolean}
     */
    isPaymentOnly()
    {
        // pagamentos activos
        let activePayment = this.store.biipPagamento || this.store.verConta;

        // verifica se a ementa esta disponivel
        let canSeeMenu = true === this.store.use_ementa;
                
        return this.hasTableNumber() && !this.hasPrePayment() && !this.isMenuOnly() && activePayment && !canSeeMenu;
    }

    isNearToStore(){     
        //nao esta perto   
        if(this.geolocationNeed && !this.geolocation.nearToBusiness){
            return false;
        }

        return true;        
    }

    btnCallEmployee(){
        return !this.isMenuOnly() && this.store.chamarEmpregado && this.isNearToStore();
    }

    /**
     * @method {identifica se tem ou nao prepagamento}
     * @returns {Boolean}
     */
    hasPrePayment() {
        let prePayment = this.store.mesaPagamento == 1 ? true : false;

        return prePayment;
    }

    // /**
    //  * Verifica se trata de uma situação em takeaway
    //  * @method isTakeAway
    //  * @returns {Boolean}
    //  */
    // isTakeAway(){
    //     let isTakeAwayCase = this.store.pedidosTakeAway == true && this.serial.tableNumber == 0;
    //     return isTakeAwayCase;
    // }

    /**
     * Para mesas ZERO, apenas são mostradas a ementa ( biipLTE )
     * onde não são permitidos pedidos ao restaurante.
     * @method isMenuOnly
     * @returns { boolean }
     */
    isMenuOnly()
    {
        return this.hasTableNumber() && this.serial.tableNumber == 0;
    }

    /**
     * Verifica se existe o numero da mesa no storeSerial
     * @returns 
     */
    hasTableNumber(){
        return 'tableNumber' in this.serial && !isNaN(this.serial.tableNumber) ? true : false;
    }

    /**
     * Verifica se tem geolocation ativa e retorna os dados
     * de latitude/longitude
     */
    async handleGeolocationNeed() {
        if (this.checkGeolocationNeed()) {
            let geoClass = new Geolocation();
            await geoClass.requestLocation();
        }
    }

    /**
     * Verifica os idiomas disponiveis para a ementa.
     * e se há necessidade do seletor de idiomas     
     */
    async handleSelectLanguageNeed() {
        let listingLang = await this.store.idiomas;
        let qtdLang = listingLang?.length;
        if (1 !== qtdLang ) {             
            store.dispatch('Site/setSelectLangNeed', true , {
                root: false
            });
        }
    }    

    /**
     * Verifica se a geolocation está ativo na loja
     * se estiver retorna os dados de latitude e longitude
     * @returns {Boolean}
     */
    checkGeolocationNeed() {
        let needCheckGeo = false;

        // ? raios definidos como zero ou um, não devem solicitar verificação de localizacao e se comportar como se estivesse no restaurante
        // ? a propriedade ENV == 'dev' sinaliza o modo debugging da aplicacao
        if (
            "dev" == this.serial.env || [0, 1].includes(this.store.raioDistancia) ||
            this.hasPrePayment()
        ) {
            // * recupera os dados atual da propriedade de geoloalizacao
            let geodata = this.geolocation;
            // * sinaliza que o dispositivo está próximo ao restaurante
            geodata.nearToBusiness = true;
            // * atualiza as configuraçoes de geolocalizacao
            store.dispatch("Site/setGeolocation", geodata, {
                root: true
            });

            needCheckGeo = false;
        } else {
            needCheckGeo =
                // ? verifica se ha um numero de mesa definida nos parametros da requisicao
                this.serial.tableNumber &&
                0 != this.serial.tableNumber &&
                // ? verifica se pedidos ao restaurante estao ativos
                (true === this.store.pedidosRestaurante ||
                    // ? ou se e permitido chamar o empregado e tens pedidos ao restaurantes inativos
                    (false === this.store.pedidosRestaurante &&
                        true === this.store.chamarEmpregado));
        }

        store.dispatch('Site/setGeolocationNeed', needCheckGeo, {root: true})

        return needCheckGeo;
    }

    /** Recupera a imagem de um produto */
    getProductUrlImage(cFamilia, cProductCode)
    {
        const urlImageBiip = process.env.VUE_APP_IMAGEURL || 'https://biip-backend.zonesoft.org';
        let urlImage = "";
        if (cFamilia && cProductCode){
            urlImage = `${urlImageBiip}/${this.serial.terminal}/${this.serial.storeId}/image/${cFamilia}/${cProductCode}`;
        }        
        return urlImage;
    }
}